@import '~@styles/base.scss';

.register-user {
  width: 95%;

  label {
    display: flex;
    flex-direction: column;

    input {
      height: 38px;
      box-sizing: border-box;
      width: 100%;
    }
  }

  &__header {
    // TODO: wrap in margin
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 46px;

    color: #25282b;
  }

  &__form {
    background: #ffffff;
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
    border-radius: 8px;
    width: 100%;
    padding: 20px;
  }
}

.row {
  display: flex;
  align-items: center;
  width: calc(100% + 20px);
  margin: 0 0 0 -20px;
  padding-bottom: 10px;

  &--spaced {
    justify-content: space-between;
    margin-top: 10px;
  }
}

.row > * {
  margin: 0 0 0 20px;
}

.preffered-comm {
  flex: 1 1 0;

  &__btn {
    flex: 1 1 0;
    height: 40px;
    background-color: transparent;
    border: 1px solid #c4c4c4;
    cursor: pointer;
  }

  &__btn-container {
    display: flex;
    align-items: center;
  }
}

.selected-btn {
  // TODO: add color variable
  background: #4094f7;
  color: $color-white;
}

.btn {
  @include primary-btn;
  padding: 4px;

  &--red {
    background: #f50057;
  }

  &--margined {
    margin-right: 24px;
  }
}

.double-wide {
  flex: 2 1 0;
}

.single-wide {
  flex: 1 1 0;
}
