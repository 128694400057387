.header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: 0.2px;
  color: #25282b;
  margin-bottom: 24px;
}

.form {
  background: #ffffff;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
}

.row {
  display: flex;
  width: calc(100% + 15px);
  margin: 0 0 0 -15px;
  padding: 10px 0;

  & > * {
    margin: 0 0 0 15px;
  }

  &__question {
    flex: 12 1 0;
  }

  &__type {
    flex: 4 1 0;
  }

  &__number {
    flex: 2 1 0;
  }

  &__range {
    flex: 2 1 0;
  }
}

.ranges {
  display: flex;

  &__max {
    margin-left: 8px;
  }
}

.btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;

  button {
    margin-left: 0;
  }
}

.mcModal {
  &__num-questions {
    display: flex;
    gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

.question-choices {
  display: flex;
  justify-content: space-between;
  border: 1px solid rgb(207, 207, 207);
  padding: 0 10px;
  border-radius: 5px;

  &__not-mc {
    display: None;
  }

  &__mc {
    display: None;
  }

  &__span {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-right: 6px;
  }
  &__btn {
    display: flex;
    background-color: transparent;
    cursor: pointer;
  }
}

.btn-cancel {
  background-color: #d14c4c;
  margin-right: 10px;
}
