@import "~styles/variables.scss";

.content {
    background-image: url("~assets/pngs/TechBG.png");
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.header {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.2px;
    color: #25282B;
    margin-bottom: 24px;
}

.back {
    cursor: pointer;
}