@import "~@styles/variables.scss";

.content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.tiles {
    display: flex;
}

.tile {
    width: 255px;
    height: 88px;
    border-radius: 8px;
    background: white;
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
    // margin-right: 30px;

    display: flex;
    align-items: center;

    &__icon {
        margin-left: 24px;
        margin-right: 24px;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__header {
        // TODO: wrap in mixin
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: #25282B;
    
        &--large {
            // TODO: wrap in mixin (h5)
            font-family: Lato;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 26px;
            color: #25282B;
            margin-top: 24px;
            margin-left: 24px;
        }
    }

    &__text {
        color: #336CFB;
        font-family: Lato;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }

    &--large {
        width: 100%;
        height: fit-content; 
        flex-direction: column;
        align-items: flex-start;
        margin-top: 30px;
    }

    &__graph {
        width: 95%;
        height: 300px;
        margin-top: 10px;
        margin-left: 24px;
        margin-bottom: 30px;
    }
}

.table {
    width: 95%;
    border-radius: 8px;
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
    background: white;
    margin-bottom: 20px;

    &__head {
        background-color: rgba(232, 232, 232, 0.2);
    }

    &__footer {
        padding: 20px 0 24px 18px;
    }
}

.user-icon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.username {
    display: flex;
    align-items: center;

    &__text {
        margin-left: 12px;
    }
}

.buttons {
    display: flex;
    align-items: center;

    &__btn {
        background-color: transparent;
        border: none;
        margin-right: 6px;
    }
}

.table-container {
    margin-top: 24px;
    width: 100%;
}

.margin {
    height: 30px;
}

.header {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.2px;
    color: #25282B;
    margin-bottom: 24px;
}

.tablerow {
    &:hover {
        background-color: #e3e3e3;
        cursor: pointer;
    }
}

.back {
    cursor: pointer;
}