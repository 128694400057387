@import '~styles/variables.scss';

.content {
  background-image: url('~assets/pngs/TechBG.png');
}

.header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: 0.2px;
  color: #25282b;
  margin-bottom: 24px;
}

.back {
  cursor: pointer;
}
