@import "~@styles/variables.scss";

.content {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.header {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.2px;
    color: #25282B;
}

.table {
    border-radius: 8px;
    box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
    background: white;

    &__head {
        background-color: rgba(232, 232, 232, 0.2);
    }

    &__footer {
        padding: 20px 0 24px 18px;
    }
}

.buttons {
    display: flex;
    align-items: center;

    &__btn {
        background-color: transparent;
        border: none;
        margin-right: 6px;
        cursor: pointer;
    }
}

.container {
    margin-top: 24px;
}

.tablerow {
    &:hover {
        background-color: #e3e3e3;
        cursor: pointer;
    }
}

.back{
    cursor: pointer;
}
