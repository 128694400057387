@import '~@styles/variables.scss';

.container {
  padding-bottom: 3.5625rem;
}

.table {
  border-radius: 0.5rem;
  box-shadow: 0rem 0.75rem 1.625rem rgba(16, 30, 115, 0.06);
  background: white;

  &__head {
    background-color: rgba(232, 232, 232, 0.2);
  }

  &__footer {
    padding: 1.25rem 0 1.5rem 1.125rem;
  }
}

.user-icon {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.username {
  display: flex;
  align-items: center;

  &__text {
    margin-left: 0.75rem;
  }
}

.todos {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.todo {
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  border-radius: 0.375rem;
  margin-right: 0.375rem;

  // TODO: put in mixin
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.buttons {
  display: flex;
  align-items: center;

  &__btn {
    background-color: transparent;
    border: none;
    margin-right: 0.375rem;
  }
}

.red {
  // TODO : put in variable
  background-color: #ffefeb;
  color: #cc0905;
}

.orange {
  color: orange;
}

.blue {
  // TODO : put in variable
  background-color: #ebf7ff;
  color: #0452c8;
}

.yellow {
  // TODO : put in variable
  background-color: #fffcc2;
  color: #835101;
}

.green {
  // TODO : put in variable
  background-color: #ebfff1;
  color: #119c2b;
}

.grey {
  // TODO: put in variable
  background-color: #eef0f2;
  color: #252c32;
}

.transparent {
  background-color: transparent;
}

.flag {
  margin-right: 1rem;
}

.tablerow {
  cursor: pointer;
  &:hover {
    background-color: #e3e3e3;
  }
}

.click {
  cursor: pointer;
}

.header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.875rem;
  letter-spacing: 0.0125rem;
  color: #25282b;
  margin-bottom: 1.5rem;
}

.top-section {
  &__search {
    width: 21.875rem;
    height: 2.5rem;
    background: $color-white;
    border-radius: 6.25rem;
    display: flex;
    align-items: center;
    margin-bottom: 1.875rem;

    &-icon {
      margin-left: 1rem;
    }

    &-input {
      // put in mixin later
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 0.8125rem;
      line-height: 1rem;

      border: none;
      width: 80%;
      margin-left: 0.75rem;
    }
  }
}
