@import '~@styles/base.scss';

.alerts {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include media('lg') {
    flex-direction: column;
  }
}

.flag {
  margin-right: 1rem;
}
