// Colors
$color-white: #FFFFFF;
$color-neutral: #F6F8FB;
$color-border: #E8E8E8;
$color-light-blue: #D7EDFF;
$color-blue: #336CFB;
$color-blue-2: #4094F7;
$color-light-grey-5: #F6F8F9;
$color-orange: #FF3700;



// Lengths
$sidebar-width: 16rem;

// Z-Index
$sidebar-z-index: 100;