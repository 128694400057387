@import '~@styles/base.scss';

.content {
    margin-left: calc(#{$sidebar-width} + 30px);
    width: 100%;
    height: 100%;
    margin-right: 38px;
}

.modal {

    &__header {
        display: flex;
        align-items: center;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.2px;
        color: #25282B;
        margin-bottom: 10px;
    }

    &__info {
        display: flex;
        margin-bottom:20px;
    }

    &__input {
        height: 36px;
        width: 70%;
    }

    &__btns {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__editor {
       min-height: 450px;
       padding: 2px;
       border: 1px solid grey;
       margin-bottom: 20px; 
    }

    &__dropdown {
        width: 30%;
        margin-left: 5px;
    }
}

button {
    @include primary-btn;
    margin-left: 10px; 
}

.header {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: 0.2px;
    color: #25282B;
    margin-bottom: 24px;
}

.username {
    display: flex;
    align-items: center;

    &__text {
        margin-left: 12px;
    }
}

.user-icon {
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.trash {
    background-color: transparent;
}

.tablerow {
    &:hover {
        background-color: #e3e3e3;
    }
}
