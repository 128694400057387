@import '~@styles/base.scss';

.app-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.routes-container {
    margin-left: $sidebar-width;
    width: 100%;

    @include media('md') {
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 60px;
    }
}

.appbar {
    &__logo {
        height: 35px;
        width: 155px;
    }
}

.sidebar-container {
    @include media('md') {
        display: none;
    }
}