@import "~@styles/variables.scss";

.top-section {
    display: flex;
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
    width: 100%;
    align-items: center;

    &--right-aligned {
        width: 100%;
        height: 2.5rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &--left-aligned {
        width: 100%;
        display: flex;
        align-self: flex-end;
    }

    &__search {
        width: 21.875rem;
        height: 2.5rem;
        background: $color-white;
        border-radius: 6.25rem;
        display: flex;
        align-items: center;

        &-icon {
            margin-left: 1rem;
        }

        &-input {
            // put in mixin later
            font-family: Lato;
            font-style: normal;
            font-weight: normal;
            font-size: 0.8125rem;
            line-height: 1rem;

            border: none;
            width: 80%;
            margin-left: 0.75rem;
        }
    }

    &__btn {
        height: 2rem;
        border: none;
        border-radius: 0.375rem;
        background-color: $color-blue-2;
        cursor: pointer;
        &-text {
            color: $color-light-grey-5;
            margin: 0.25rem 0.75rem;

            // put in a mixin later
            font-style: normal;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 1.5rem;
            text-align: center;
            letter-spacing: -0.006em;
        }

        &:disabled {
            background-color: #777777;
            &:hover {
                cursor: not-allowed;
            }
        }
    }

    &__notifications {
        display: flex;
        align-items: center;
        margin-left: 24.5px;

        &-btn {
            border: none;
            background-color: $color-orange;
            border-radius: 6px;

            // put in mixin later
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;

            color: $color-white;
            cursor: pointer;
        }
    }

    &__profile {
        width: 40px;
        height: 40px;
        margin-left: 13px;
        background-image: url("../../../barnie.jpeg");
        background-size: contain;
        border-radius: 50%;
    }
}
