@import '~@styles/variables.scss';

.sidebar {
  width: $sidebar-width;
  height: 100%;
  box-shadow: 0.25rem 0rem 1rem rgba(16, 30, 115, 0.08);
  position: fixed;
  z-index: $sidebar-z-index;
  top: 0;
  left: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background-color: $color-white;

  &__logo {
    height: 3.4375rem;
    width: 15.9375rem;
    margin-top: 1.875rem;
    margin-bottom: 1.25rem;
  }

  &__item {
    width: $sidebar-width;
    height: 3rem;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;

    &--active {
      background-color: $color-border;
      width: $sidebar-width;
      height: 3rem;
      border: none;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: inherit;
    }

    &-text {
      // add to mixin later
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 0.875rem;
      line-height: 1.25rem;

      margin-left: 0.9375rem;
    }

    &-icon {
      margin-left: 1.125rem;
      margin-left: 1.125rem;
      filter: brightness(80%);
    }
    &-icon-dark {
      margin-left: 1.125rem;
      filter: brightness(60%);
    }
    &-icon-darkest {
      margin-left: 1.125rem;
      filter: brightness(20%);

      &:hover {
        background-color: $color-border;
      }
    }
  }

  &__header {
    font-weight: bold;
    color: #3494b6;
    font-size: 18px;
    margin-bottom: 10px;
  }
}

.solid {
  width: $sidebar-width;
  border-top: 0.0625rem solid $color-border;
}

.logout {
  justify-content: left;
  padding-left: 1.25rem;
  margin-left: 0;
  border-radius: 0;
}
