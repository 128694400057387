.publisher {
  height: 90px;
  width: 160px;
  float: left;
  object-fit: cover;
  cursor: pointer;
}
.lSubscriber {
  width: calc((100vh - 50px) * 1.777777);
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.pSubscriber {
  height: calc(100vw / 1.777777);
  width: 100vw;
  max-width: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.streamcomponent div {
  display: flex;
  background: #f8f8f8;
  color: #777777;
  font-weight: bold;
  flex-direction: column;
  border: 1px #333333 solid;
  margin-top: 5px;
  margin-right: 5px;
}

p {
  margin: 0;
}

.canvas-wrap {
  position: relative;
  width: '800px';
  height: '600px';
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

#name {
  position: absolute;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  margin-left: 10px;
}

.canvas-wrap .recording {
  position: absolute;
  color: white;
  z-index: 50;
  font-size: 50px;
  top: 70px;
  background: rgba(255, 0, 0, 0.75);
  opacity: 0.75;
}

.canvas-wrap .logo {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  z-index: 50;
  top: 0px;
  right: 0px;
  height: 50px;
  width: auto;
  opacity: 0.75;
}
