@import '~@styles/variables.scss';
@import '~@styles/base.scss';

.enrollment-form {
  padding: 0;
  background-color: transparent;

  &__btn {
    height: 40px;
    border: none;
    border-radius: 0.375rem;
    background-color: $color-blue-2;
    color: $color-light-grey-5;
    cursor: pointer;

    // put in a mixin later
    font-style: normal;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    text-align: center;
    letter-spacing: -0.006em;
  }

  &__btn:disabled {
    background-color: $color-light-grey-5;
    color: $color-white;
  }
}

.info-container {
  background-color: white;
  // margin: 10px;
  margin-top: 10px;
  padding: 10px 20px;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;

  &__row {
    display: flex;
    align-items: top;
    width: calc(100% + 20px);
    margin: 0 0 0 -20px;
    padding: 10px 0;
  }

  &__row > * {
    margin: 0 0 0 20px;
  }

  &__care-header {
    // TODO: wrap in mixin later
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }

  label {
    display: flex;
    flex-direction: column;

    input {
      height: 38px;
      // TODO: add to color variables
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      width: 100%;
    }
  }
}

.care-plans {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  padding: 10px;

  &__range {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;

    &-title {
      width: 50%;
    }

    &-input {
      margin-left: 5px;
      height: 38px;
      width: 53px;
      // TODO: add to color variables
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
    }
  }

  &__survey {
    display: flex;

    &--id {
      flex: 1 1 0;
    }

    &--frequency {
      margin-left: 5px;
      flex: 3 1 0;
    }
  }
}

.first-last-name {
  flex: 2 1 0;
}

.date-of-birth {
  flex: 1 1 0;
}

.address {
  flex: 3 1 0;

  &-details {
    flex: 1 1 0;
  }
}

.email {
  flex: 3 1 0;
}

.phone-number {
  flex: 1 1 0;
}

.preffered-comm {
  flex: 1 1 0;

  &__btn {
    flex: 1 1 0;
    height: 40px;
    background-color: transparent;
    border: 1px solid #c4c4c4;
    cursor: pointer;
  }

  &__btn-container {
    display: flex;
    align-items: center;
  }
}

.emergency-name {
  flex: 2 1 0;
}

.relation-to-patient {
  flex: 2 1 0;
}

.emergency-phone-number {
  flex: 1 1 0;
}

.health-card-number {
  flex: 2 1 0;
}

.doctor-name {
  flex: 2 1 0;
}

.date-of-surgery {
  flex: 1 1 0;
}

.care-plan {
  flex: 1 1 0;
}

.selected-btn {
  // TODO: add color variable
  background: #4094f7;
  color: $color-white;
}

.btns-row {
  justify-content: flex-end;
}

.upload-photo-btn {
  margin-right: 28px;
}

.header {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 46px;
  letter-spacing: 0.2px;
  color: #25282b;
}

.info-container__row {
  display: flex;
  flex-wrap: wrap;
}

.requirements > * {
  margin-right: 20px;
}

.cancel {
  background-color: #d14c4c;
}

.btn {
  @include primary-btn;
  padding: 4px;

  &--red {
    background: #f50057;
  }

  &--margined {
    margin-right: 24px;
  }
}

.consent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
