$breakpoints: (
    'sm': 720px,
    'md': 950px,
    'lg': 1200px        
) !default;

@mixin btn {
    cursor: pointer;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.006em;
    border-radius: 6px;
}

@mixin primary-btn {
    @include btn;
    color: #F6F8F9;
    background: #4094F7;
}

@mixin secondary-btn {
    @include btn;
    color: black;
    background: #79D2DE;
}

@mixin media($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
      @media (max-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }
   
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }