@import '~@styles/variables.scss';

:root {
  --amplify-primary-color: #193139;
  --amplify-secondary-color: #193139;
  --amplify-primary-contrast: white;
  --amplify-background-color: #2a81a7;
  --link-color: white;
  --subtitle-color: white;
  --amplify-grey: white;
  --box-shadow: 0px 0px;
}

amplify-authenticator {
  --box-shadow: 0px 0px;
}

amplify-button {
  background-color: grey;
  --background-color: grey !important;
}

.homePage {
  align-items: center;
  flex: 1;
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: #2a81a7;
}

.leftPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-color: white;
  width: 35vw;
}

.logos {
  width: 90%;
}

.rightPage {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 65vw;
  background-color: #2a81a7;
}

.leftPage img {
  max-width: 90%;
  max-height: 100%;
}

.leftPage img {
  margin: 30px;
}

@media only screen and (max-width: 900px) {
  .homePage {
    flex-direction: column;
  }

  .leftPage {
    width: 100vw;
  }

  .rightPage {
    width: 100vw;
  }
}
